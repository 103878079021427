const HomePageLandingText = () => {
  return (
    <div className="flex flex-col gap-4">
      <h1
        className="text-center"
        style={{
          color: "white",
        }}
      >
        Hi, I'm Michael
      </h1>
      <h4
        className="text-center"
        style={{
          color: "white",
        }}
      >
        This is why I am so awesome
      </h4>
    </div>
  );
};
export default HomePageLandingText;
