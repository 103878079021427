import { Form, Input } from "antd";

const HomePageForm = () => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      style={{
        height: "100vh",
      }}
    >
      <div className="flex gap-2">
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input placeholder="First name" size="large" />
        </Form.Item>
        <Form.Item
          name="company"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input placeholder="Company" size="large" />
        </Form.Item>
      </div>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
          },
        ]}
      >
        <Input type="email" placeholder="Email" size="large" />
      </Form.Item>
    </Form>
  );
};

export default HomePageForm;
