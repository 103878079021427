import { useLayoutEffect, useState } from "react";
import Header from "./Header";
import HomePageForm from "./HomePageForm";
import HomePageLandingText from "./HomePageLangingText";

const VideoText = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        className="w-full"
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <video
          autoPlay
          loop
          muted
          src="/home_video_2.mp4"
          style={{
            ...(isMobile
              ? {
                  height: "100vh",
                }
              : {
                  width: "100%",
                  objectFit: "cover",
                  height: "100vh",
                }),
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
          }}
        >
          <Header />
          <div style={{ padding: "1rem" }}>
            <div
              className="flex flex-col items-center justify-center gap-5"
              style={{ height: "100%", overflow: "hidden", marginTop: "22vh" }}
            >
              <HomePageLandingText />
              <HomePageForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoText;
