import VideoText from "./VideoText";
import "./index.css";

const HomePage = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <VideoText />
    </div>
  );
};

export default HomePage;
