const Header = () => {
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        width: "100%",
        background: "rgba(255, 255, 255, 0.1)",
      }}
    >
      <div style={{ padding: "1rem" }}>
        <h2
          style={{
            color: "white",
          }}
        >
          Site de Michael
        </h2>
      </div>
    </div>
  );
};
export default Header;
